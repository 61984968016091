import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Router } from "@reach/router";
import "./App.css";
import createStore from "./store";
import { Provider, useSelector } from "react-redux";
import "./firebase";
import Login from "./components/Login";
import Worklists from "./components/Worklists";
import Worklist from "./components/WorklistGroup";
import { PersistGate } from "redux-persist/integration/react";
import FullScreen from "react-full-screen";
import { AppState } from "./reducers";

const { store, persistor } = createStore();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CssBaseline />
        <Screen />
      </PersistGate>
    </Provider>
  );
};

const Screen: React.FC = () => {
  const fullscreen = useSelector((state: AppState) => state.store.fullscreen);
  return (
    <FullScreen enabled={fullscreen}>
      <Router>
        <Login path="/" />
        <Worklists path="/worklists" />
        <Worklist path="/worklistGroup/:id" />
      </Router>
    </FullScreen>
  );
};

export default App;
