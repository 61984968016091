import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";

// const config =
//   !process.env.NODE_ENV || process.env.NODE_ENV === "development"
//     ? {
//         apiKey: "AIzaSyDWy48K8wfioB9bwsz3Dv6oWh0zeZvhVc0",
//         authDomain: "queue-api-dev.firebaseapp.com",
//         databaseURL: "https://queue-api-dev.firebaseio.com",
//         projectId: "queue-api-dev",
//         storageBucket: "queue-api-dev.appspot.com",
//         messagingSenderId: "1052459694066"
//       }
//     : {
//         apiKey: "AIzaSyC7lrtUS5lzA54OPICemxpGwqrNzaQDsn0",
//         authDomain: "queue-api.firebaseapp.com",
//         databaseURL: "https://queue-api.firebaseio.com",
//         projectId: "queue-api",
//         storageBucket: "queue-api.appspot.com",
//         messagingSenderId: "1063204016925"
//       };

const config = {
  apiKey: "AIzaSyC7lrtUS5lzA54OPICemxpGwqrNzaQDsn0",
  authDomain: "queue-api.firebaseapp.com",
  databaseURL: "https://queue-api.firebaseio.com",
  projectId: "queue-api",
  storageBucket: "queue-api.appspot.com",
  messagingSenderId: "1063204016925"
};

firebase.initializeApp(config);
