import React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useWorklistGroups } from "../hooks/useWorklists";
import { Card, Container, CardContent, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { map } from "lodash";

export const Worklists: React.FC<RouteComponentProps> = () => {
  const [worklistGroups, loading] = useWorklistGroups();

  const dispatch = useDispatch();

  if (!worklistGroups && !loading) {
    return (
      <Container
        style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
      >
        <Typography variant="h4">No Worklist Groups Configured</Typography>
      </Container>
    );
  }

  return (
    <Container>
      {map(worklistGroups, (worklistGroup, id) => {
        return (
          <Card
            style={{ flex: 1, margin: 10 }}
            key={id}
            onClick={() => {
              dispatch({ type: "SET_WORKLIST_GROUP", payload: id });
              navigate(`/worklistGroup/${id}`, { state: worklistGroup });
            }}
          >
            <CardContent>
              <Typography variant="h2">{worklistGroup.name}</Typography>
            </CardContent>
          </Card>
        );
      })}
    </Container>
  );
};

export default Worklists;
