import produce from "immer";
import { combineReducers } from "redux";

export const SET_STORE_ID = "SET_STORE_ID";
export const TOGGLE_FULLSCREEN = "TOGGLE_FULLSCREEN";
export const SET_WORKLIST_GROUP = "SET_WORKLIST_GROUP";

const initialState = {
  storeId: "",
  fullscreen: false,
  worklistGroup: ""
};

const storeReducer = (state = initialState, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_STORE_ID: {
        draft.storeId = action.payload.storeId;
        break;
      }
      case TOGGLE_FULLSCREEN: {
        draft.fullscreen = !state.fullscreen;
        break;
      }
      case SET_WORKLIST_GROUP: {
        draft.worklistGroup = action.payload;
        break;
      }
    }
  });

const rootReducer = combineReducers({
  store: storeReducer
});

export default rootReducer;

export type AppState = ReturnType<typeof rootReducer>;
