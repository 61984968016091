import { useObjectVal } from "react-firebase-hooks/database";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { IWorkitem, IProductOption } from "@queue/types";
import { useSelector } from "react-redux";
import firebase from "firebase/app";
import { AppState } from "../reducers";

export const useWorklistGroups = () => {
  const storeId = useSelector((state: AppState) => state.store.storeId);
  const worklistsRef = firebase
    .database()
    .ref("branches")
    .child(storeId)
    .child("worklistGroups");

  return useObjectVal<any>(worklistsRef);
};

// export const useWorklists = () => {
//   const worklistGroupId = useSelector((state: AppState) => state.store.worklistGroup);
//   const worklistGroupRef = firebase
//     .database()
//     .ref("branches")
//     .child(storeId)
//     .child("worklistGroups").child(worklistGroupId);

// }

export const useWorklist = (worklistId: string) => {
  const storeId = useSelector((state: AppState) => state.store.storeId);
  const worklistRef = firebase
    .database()
    .ref("branches")
    .child(storeId)
    .child("worklists")
    .child(worklistId);

  return useObjectVal<any>(worklistRef);
};

export const useWorkitems = (worklistId = "default") => {
  const storeId = useSelector((state: AppState) => state.store.storeId);
  const workitemsRef = firebase
    .firestore()
    .collection("stores")
    .doc(storeId)
    .collection("workitems")
    .where("worklist", "==", worklistId)
    .where("status", "==", 0)
    .orderBy("createdAt");

  return useCollectionData<IWorkitem>(workitemsRef, { idField: "id" });
};

export const useProductOptions = () => {
  const storeId = useSelector((state: AppState) => state.store.storeId);
  const productOptionsRef = firebase
    .database()
    .ref("branches")
    .child(storeId)
    .child("productOptions");

  return useObjectVal<any>(productOptionsRef);
};
