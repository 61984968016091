import React from "react";
import { map, padStart } from "lodash";
import { Card, Typography } from "@material-ui/core";
import { IWorkitem } from "@queue/types";
import firebase from "firebase/app";
import { Colors } from "../colors";
import invert from "invert-color";
import Timeago from "react-timeago";
import { AppState } from "../reducers";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

interface Props {
  workitem: IWorkitem;
  selectedItem: string;
  setSelectedItem(id: string): void;
}

const Workitem: React.FC<Props> = ({
  workitem,
  selectedItem,
  setSelectedItem
}) => {
  const colorIndex = workitem.orderNumber ? workitem.orderNumber % 20 : 0;
  const itemSelected = selectedItem === workitem.id;

  const color = itemSelected ? "#ffeb3b" : Colors[colorIndex];
  const fontColor = invert(color, true);
  const storeId = useSelector((state: AppState) => state.store.storeId);

  const handleSelect = () => {
    if (itemSelected) {
      const workitemRef = firebase
        .firestore()
        .collection("stores")
        .doc(storeId)
        .collection("workitems")
        .doc(workitem.id);

      workitemRef.update({
        staff: "kds",
        completedAt: Date.now(),
        status: 1
      });
    } else {
      setSelectedItem(workitem.id);
    }
  };

  let name = workitem.productName;

  if (workitem.variantName) {
    name = name + " " + workitem.variantName;
  }

  return (
    <Card
      key={workitem.id}
      style={{
        margin: 5,
        marginRight: 10,
        marginLeft: 10,
        backgroundColor: color
      }}
      onClick={handleSelect}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 10,
          paddingRight: 10
        }}
      >
        <Typography variant="h5" style={{ flex: 1, color: fontColor }}>
          {padStart(workitem.orderNumber + "", 4, "0")}
        </Typography>
        <Typography
          variant="h5"
          style={{ flex: 2, color: fontColor, textAlign: "center" }}
        >
          {name + " x " + workitem.quantity}
        </Typography>
        <Typography
          variant="h5"
          style={{ flex: 1, color: fontColor, textAlign: "end" }}
        >
          {workitem.orderName}
        </Typography>
      </div>
      {workitem.productOptions && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: 10,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {map(workitem.productOptions, (productOption, id) => {
            const quantity = productOption.quantity;
            let name = productOption.name;

            if (productOption.subName) {
              name = name + " | " + productOption.subName;
            }

            const displayName = `* ${name} ${
              quantity > 1 ? " x " + quantity : ""
            }`;
            return (
              <Typography key={id} variant="h5" style={{ color: fontColor }}>
                {displayName}
              </Typography>
            );
          })}
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: 10,
          paddingLeft: 10
        }}
      >
        <Typography variant="h5" style={{ color: fontColor }}>
          {moment(workitem.createdAt).format("HH:mm")}
        </Typography>
        <Typography variant="h5" style={{ color: fontColor }}>
          <Timeago date={workitem.createdAt} />
        </Typography>
        <Typography variant="h5" style={{ color: fontColor }}>
          {workitem.staffName}
        </Typography>
      </div>
    </Card>
  );
};

export default Workitem;
