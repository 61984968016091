import React from "react";
import { RouteComponentProps } from "@reach/router";
import { map } from "lodash";
import Worklist from "./Worklist";
import { Box } from "@material-ui/core";
// import { useWorklists } from "../hooks/useWorklists";
// import { useSelector } from "react-redux";
// import { useFirebaseConnect } from "react-redux-firebase";
// import { AppState } from "../reducers";

const WorklistGroup: React.FC<RouteComponentProps> = props => {
  const [selectedItem, setSelectedItem] = React.useState("");
  const worklists = props && props.location && props.location.state.worklists;

  return (
    <div style={{ width: "100%" }}>
      <Box style={{ display: "flex", flex: 1 }}>
        {map(worklists, (_, worklistId) => (
          <Worklist
            key={worklistId}
            worklistId={worklistId}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        ))}
      </Box>
    </div>
  );
};

export default WorklistGroup;
