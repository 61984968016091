import React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/app";
import { useDispatch } from "react-redux";
import logo from "../img/logo.png";
import { Card, Typography, TextField, Button } from "@material-ui/core";
import { useAuthState } from "react-firebase-hooks/auth";

// const uiConfig = {
//   // Popup signin flow rather than redirect flow.
//   signInFlow: "popup",
//   // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
//   signInSuccessUrl: "/worklists",
//   // We will display Google and Facebook as auth providers.
//   signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID]
// };

const Login: React.FC<RouteComponentProps> = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showSnack, setShowSnack] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const dispatch = useDispatch();

  const handleSignIn = async () => {
    try {
      setSubmitting(true);
      await firebase.auth().signInWithEmailAndPassword(email, password);

      const currentUser = firebase.auth().currentUser;
      const uid = currentUser?.uid;

      const permissionsSnap = await firebase
        .firestore()
        .collection("permissions")
        .doc(uid)
        .collection("stores")
        .get();

      let storeId = "";
      permissionsSnap.forEach(snap => {
        storeId = snap.id;
      });

      console.log({ storeId });
      dispatch({ type: "SET_STORE_ID", payload: { storeId } });

      navigate("/worklists");
    } catch (error) {
      setSubmitting(false);
      console.log({ error });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 30,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img src={logo} alt="Logo" height={100} width={100} />
        <Typography variant="h1" style={{ margin: 40 }}>
          KDS
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          maxWidth: 400,
          alignSelf: "center"
        }}
      >
        <TextField
          label="Email"
          onChange={val => setEmail(val.target.value)}
          type="email"
        />

        <TextField
          label="Password"
          onChange={val => setPassword(val.target.value)}
          type="password"
        />
        <Button
          onClick={handleSignIn}
          variant="outlined"
          color="secondary"
          style={{ marginTop: 10, width: 400 }}
          disabled={submitting}
        >
          Login
        </Button>
      </div>
    </div>
  );
};

export default Login;
