import React from "react";
import { useWorklist, useWorkitems } from "../hooks/useWorklists";
import { map, size } from "lodash";
import { Card, Typography } from "@material-ui/core";
import Workitem from "./Workitem";
import { useDispatch } from "react-redux";

interface Props {
  worklistId: string;
  selectedItem: string;
  setSelectedItem(id: string): void;
}

const Worklist: React.FC<Props> = props => {
  const [worklist, loading] = useWorklist(props.worklistId);
  const [workitems] = useWorkitems(props.worklistId);
  const dispatch = useDispatch();

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <Card
        onClick={() => dispatch({ type: "TOGGLE_FULLSCREEN" })}
        style={{
          display: "flex",
          margin: 10,
          height: 70,
          justifyContent: "space-between",
          padding: 10
        }}
      >
        <Typography variant="h3">{worklist && worklist.name}</Typography>
        <Typography variant="h3">{size(workitems)}</Typography>
      </Card>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {map(workitems, workitem => {
          return (
            <Workitem
              workitem={workitem}
              key={workitem.id}
              selectedItem={props.selectedItem}
              setSelectedItem={props.setSelectedItem}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Worklist;
